import { useRef } from "react";
import { useState } from "react";
import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Cropper } from "react-cropper";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import FileUpload from "../common/FileUpload";
import CustomTextField from "../common/CustomTextField";
import { encryptData, getObjLength } from "../helpers/utils";
import { brokerAgentsAction } from "../../redux/slices/brokers";
import CustomPhoneNumberInput from "../common/CustomPhoneNumberInput";
import { lennarSignUpAction } from "../../redux/slices/lennar/signUp";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import add_photoIcon from "../../assets/dashboard/add_photo_alternate.png";
import { closeDeleteIconStyle } from "../admin/providers/CreateProvidersForm";
import CustomizedCheckbox from "../common/customDesignedComponents/customCheckbox";
import {
  AGENTS_TERMS_LINK,
  MenuProps,
  RECAPTCHA_SITE_KEY,
  secretKeyEncrypt,
} from "../helpers/constants";

const fieldsValidations = (base_route, update_profile = false) => {
  return Yup.object().shape({
    first_name: Yup.string("Please enter your First Name.")
      .trim("The First name cannot include leading and trailing spaces")
      .strict(true)
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name is required"),
    last_name: Yup.string("Please enter your Last name.")
      .trim("The Last name cannot include leading and trailing spaces")
      .strict(true)
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name is required"),
    broker_name:
      base_route === "broker"
        ? Yup.string()
        : Yup.string("Please enter your Brokerage name.")
            .trim(
              "The Brokerage name cannot include leading and trailing spaces"
            )
            .strict(true)
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Brokerage name is required"),
    email: Yup.string("Please enter your Email Id.")
      .email("Invalid email format")
      .max(255)
      .required("Email id is required"),
    phone: Yup.string("Please enter your Phone number.")
      .typeError("That doesn't look like a phone number")
      // .positive()
      .required("Mobile number is required"),
    website: Yup.string()
      // .required("Website URL is required")
      .matches(
        /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([/\w .-]*)*\/?$/,
        "Invalid URL pattern"
      ),
    office_id: Yup.string("Please select the Office").required(
      "Office is required"
    ),
    representative:
      base_route === "broker"
        ? Yup.string()
        : Yup.string("Please select the Representative").required(
            "Representative is required"
          ),
    // profile_img: update_profile
    //   ? Yup.string().required("Please upload your Profile photo.")
    //   : Yup.string(),
    // logo: Yup.string().required("Please upload your Logo."),
    license_number: Yup.string().required("License number is required"),
    password: update_profile
      ? Yup.string()
      : Yup.string("Please enter Password.")
          .required("Password is required")
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
          )
          .test(
            "no-spaces",
            "Password cannot contain any spaces",
            (value) => !/\s/.test(value)
          ),
    old_password: !update_profile
      ? Yup.string()
      : Yup.string("Please enter Password.")
          //  .required("Password is required")
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
          )
          .test(
            "no-spaces",
            "Password cannot contain any spaces",
            (value) => !/\s/.test(value)
          )
          .when("new_password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().required("Old Password is required"),
          }),
    new_password: !update_profile
      ? Yup.string()
      : Yup.string("Please enter Password.")
          //  .required("Password is required")
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
          )
          .test(
            "no-spaces",
            "Password cannot contain any spaces",
            (value) => !/\s/.test(value)
          ),
  });
};
const AgentsSignupForm = ({
  setScreen = () => {},
  setFormValues = () => {},
  onAgentFormSubmit = () => {},
  formValues,
  update_agent_profile = false,
  updateAgentProfile = () => {},
  closeAgentProfileModal = () => {},
  base_route = "broker",
}) => {
  const dispatch = useDispatch();
  const signUpFormRef = useRef();

  // states
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [checked, setChecked] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [croppedProfileImage, setCroppedProfileImage] = useState(null);
  const [selectedProfileFile, setSelectedProfileFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [passwordcopy, setPasswordcopy] = useState(null);
  const cropperProfileRef = useRef();
  const cropperLogoRef = useRef();
  const captchaRef = useRef();
  const profileDropzoneRef = useRef();
  const logoDropzoneRef = useRef();

  const [logoLoader, setLogoLoader] = useState(false); // State for loader
  const [profileLoader, setProfileLoader] = useState(false); // State for loader

  const queryString = window.location.search;
  const rep_code = queryString.split("?")[1];

  const handleCaptchaChange = (captchaValue) => {
    console.log("captcha value: ", captchaValue);
    setRecaptchaToken(captchaValue);
  };
  const handleClickShowPassword = (setState) => setState((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onCheckedAcknowledge = (e) => {
    console.log("checked val==>", e.target.checked);
    setChecked(e.target.checked);
  };

  const { checkReCAPTCHADetails } = useSelector((state) => state?.signUpUser);
  const {
    agentCreateAccDetails,
    agentProfileData,
    agentProfileListData,
    agentProfileLoader,
    brokerDetails,
    updateAgentProfileData,
  } = useSelector((state) => state?.brokerAgents);

  const fetchAgentProfile = (values) => {
    console.log("values=>", values);
    let payload = {
      ...values,
      source: base_route,
      business_name: brokerDetails?.data?.broker?.full_name,
    };
    delete payload.passwordcopy;
    if (payload?.profile_img?.length === 0) {
      delete payload?.profile_img;
    }
    if (payload?.logo?.length === 0) {
      delete payload?.logo;
    }
    localStorage.removeItem("phone_verified");
    dispatch(
      brokerAgentsAction.getAgentProfileListData({
        url: `broker/get-agent-profile`,
        data: { ...payload },
      })
    );
  };

  useEffect(() => {
    console.log("cptcha called..", checkReCAPTCHADetails);
    if (getObjLength(checkReCAPTCHADetails) && !update_agent_profile) {
      if (checkReCAPTCHADetails?.data?.success) {
        if (localStorage.getItem("CAPTCHA_Validate") && checked) {
          fetchAgentProfile(formValues);
        }
      }
    }
  }, [checkReCAPTCHADetails]);

  useEffect(() => {
    console.log("agentProfileData..", agentProfileData);
    if (getObjLength(agentProfileData) && update_agent_profile) {
      setCroppedProfileImage(agentProfileData?.data?.agent_profile_img);
      setCroppedImage(agentProfileData?.data?.logo);
    } else {
      setCroppedProfileImage(null);
      setCroppedImage(null);
    }
  }, [agentProfileData]);

  useEffect(() => {
    if (getObjLength(agentProfileListData)) {
      if (agentProfileListData?.success) {
        if (agentProfileListData?.data?.length === 0) {
          onAgentFormSubmit(formValues, undefined);
          // } else if (agentProfileListData?.data?.length === 1) {
          //   onAgentFormSubmit(formValues, agentProfileListData?.data?.[0]);
        } else {
          dispatch(brokerAgentsAction.fetchAgentCreateAccData({}));
          dispatch(lennarSignUpAction.resetLennarSignUpState({}));
          setScreen("agents-profile-list");
        }
        // setPasswordcopy(null);
      }
      // dispatch(userSigupAction.setreCAPTCHA({}));
      setRecaptchaToken(null);
      captchaRef?.current?.reset();
    }
  }, [agentProfileListData]);

  // when user select the image
  const onFileChange = (files, type) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (type === "profile") {
        setCroppedProfileImage(null);
        setSelectedProfileFile(reader.result);
      } else {
        setCroppedImage(null);
        setSelectedFile(reader.result);
      }
    };
    reader.readAsDataURL(files[0]);
  };
  // get cropped image
  const getCropData = (filedName) => {
    // Start loading
    if (filedName === "profile_img") {
      setProfileLoader(true);
    } else {
      setLogoLoader(true);
    }

    setTimeout(() => {
      // Simulate processing delay
      if (
        filedName === "profile_img" &&
        typeof cropperProfileRef.current?.cropper !== "undefined"
      ) {
        const croppedData = cropperProfileRef.current?.cropper
          .getCroppedCanvas()
          .toDataURL();
        setCroppedProfileImage(croppedData);
        setSelectedProfileFile(null);
        signUpFormRef?.current?.setFieldValue(filedName, croppedData);
      }
      if (
        filedName === "logo" &&
        typeof cropperLogoRef.current?.cropper !== "undefined"
      ) {
        const croppedData = cropperLogoRef.current?.cropper
          .getCroppedCanvas()
          .toDataURL();
        setCroppedImage(croppedData);
        setSelectedFile(null);
        signUpFormRef?.current?.setFieldValue(filedName, croppedData);
      }

      // Stop loading after cropping is done
      setProfileLoader(false);
      setLogoLoader(false);
    }, 500); // Simulated processing delay (adjust if needed)
  };
  const triggerFileUpload = (type = "profile") => {
    if (type === "profile") {
      setTimeout(() => {
        setSelectedProfileFile(null);
      }, 500);
      const fileInput =
        profileDropzoneRef.current?.querySelector('input[type="file"]');
      if (fileInput) fileInput.click();
    } else {
      setTimeout(() => {
        setSelectedFile(null);
      }, 500);
      const fileInput =
        logoDropzoneRef.current?.querySelector('input[type="file"]');
      if (fileInput) fileInput.click();
    }
  };
  const customFileUploadInnerSection = (getInputProps, getRootProps) => {
    return (
      <div
        className="dz-message needsclick custom-dropzone"
        style={{ padding: "16px" }}
        {...getRootProps()}
      >
        <div
          className="dz-message needsclick custom-dropzone"
          style={{ padding: "16px" }}
        >
          <input {...getInputProps()} name="file" />
          <i className="d-inline-block">
            <img src={add_photoIcon} alt="add_photoIcon" />
          </i>
        </div>
      </div>
    );
  };

  const representativesList = brokerDetails?.success
    ? brokerDetails?.data?.broker?.representatives
    : [];

  const officeList = brokerDetails?.success
    ? brokerDetails?.data?.broker?.offices
    : [];

  const resp_name = representativesList?.find(
    (data) => data?.unique_code === rep_code
  );
  const validationSchema = fieldsValidations(base_route, update_agent_profile);

  return (
    <Box
      className={`box-container agents_form ${update_agent_profile && "px-0"}`}
    >
      <Box className="bg-white">
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            first_name:
              formValues?.first_name ||
              agentProfileData?.data?.first_name ||
              "",
            last_name:
              formValues?.last_name || agentProfileData?.data?.last_name || "",
            //Brokerage Name
            broker_name:
              formValues?.broker_name ||
              agentProfileData?.data?.broker_name ||
              "",
            phone:
              formValues?.phone || agentProfileData?.data?.phone_number || "",
            email: formValues?.email || agentProfileData?.data?.email || "",
            license_number:
              formValues?.license_number ||
              agentProfileData?.data?.license_number ||
              "",
            website:
              formValues?.website || agentProfileData?.data?.website || "",
            profile_img:
              formValues?.profile_img ||
              agentProfileData?.data?.agent_profile_img ||
              "",
            logo: formValues?.logo || agentProfileData?.data?.logo || "",
            office_id:
              formValues?.office_id || agentProfileData?.data?.office?.id || "",
            representative:
              formValues?.representative ||
              resp_name?.id ||
              agentProfileData?.data?.rep_id ||
              (base_route === "broker" ? "" : ""),
            password: formValues?.passwordcopy || "",
            old_password: passwordcopy || "",
            new_password: "",
          }}
          validationSchema={validationSchema}
          innerRef={signUpFormRef}
          onSubmit={(values, validation) => {
            const payload = JSON.parse(JSON.stringify(values));
            if (base_route === "broker") {
              delete payload?.broker_name;
            }
            // if (!values?.logo) {
            //   delete payload?.logo;
            // }
            if (update_agent_profile) {
              payload.password = values?.new_password?.trim();
              const encryptedNewPassword = encryptData(
                payload.password,
                secretKeyEncrypt.toString()
              );
              payload.password = encryptedNewPassword;

              payload.old_password = values?.old_password?.trim();
              const encryptedOldPassword = encryptData(
                payload.old_password,
                secretKeyEncrypt.toString()
              );
              payload.old_password = encryptedOldPassword;

              dispatch(brokerAgentsAction.getAgentProfileFormData(values));
              updateAgentProfile(payload, agentProfileData?.data);
              setPasswordcopy(values?.old_password);
            } else {
              payload.password = values?.password?.trim();
              delete payload.new_password;
              delete payload.old_password;
              const encryptedPassword = encryptData(
                payload.password,
                secretKeyEncrypt.toString()
              );
              payload.password = encryptedPassword;
              if (!recaptchaToken) {
                return;
              }
              localStorage.setItem("CAPTCHA_Validate", 1);
              if (checked) {
                dispatch(
                  userSigupAction.checkreCAPTCHA({
                    url: `validate-captcha?token=${recaptchaToken}`,
                    data: {},
                  })
                );
              }
              setPasswordcopy(values?.password);
            }

            payload.passwordcopy = values?.password;

            // payload.role = "user";
            setFormValues(payload);
          }}
        >
          {({
            values,
            setFieldValue,
            touched,
            errors,
            setTouched,
            isSubmitting,
          }) => {
            return (
              <>
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                      <Grid item xs={12} md={12}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Photo
                        </InputLabel>
                        {/* When cropper will crop the image then we will have data in the croppedProfileImage state so code will transfer to else condition */}
                        {!croppedProfileImage ? (
                          selectedProfileFile ? (
                            <>
                              {/* When select the image then this cropper will be displayed */}
                              <Cropper
                                ref={cropperProfileRef}
                                style={{ height: "150px", width: "150px" }}
                                zoomTo={0}
                                initialAspectRatio={1}
                                src={selectedProfileFile}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false}
                                guides={false}
                              />
                              <Box
                                className="d-flex justify-content-start"
                                mt={2}
                              >
                                <Button
                                  onClick={() => getCropData("profile_img")}
                                  variant="contained"
                                  className="success-btn"
                                  style={{ padding: "6px 16px" }}
                                  // color="primary"
                                >
                                  {profileLoader ? (
                                    <>
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                      &nbsp;&nbsp;Processing..
                                    </>
                                  ) : (
                                    "Crop Photo"
                                  )}
                                </Button>
                              </Box>
                            </>
                          ) : (
                            <>
                              {/* Initially there is neither cropped image nor selected image so upload image field will show */}
                              <span className="servicer-signup-form">
                                <FileUpload
                                  name="upload_doc"
                                  accept={{
                                    "image/png": [".png", ".jpeg", ".jpg"],
                                  }}
                                  multiple={false}
                                  onFileUpload={async (file) => {
                                    onFileChange(file, "profile");
                                  }}
                                  maxSize={10}
                                  dragDropText={`Drag and drop Profile picture or `}
                                  greenText="Upload a Profile picture"
                                  subText="Only .png, .jpeg, .jpg files are allowed"
                                  customFileUploadInnerSection={
                                    customFileUploadInnerSection
                                  }
                                />
                              </span>
                            </>
                          )
                        ) : (
                          <>
                            {/* After successfully cropping it will display the cropped image and delete icon for clearing the image */}
                            <Box
                              className="position-relative p-4 rounded d-flex justify-content-center align-items-center"
                              sx={{
                                backgroundColor: "#F6F9FC",
                                border: croppedProfileImage
                                  ? "1px solid #E2E8EB"
                                  : "",
                                width: "150px",
                                height: "150px",
                              }}
                            >
                              <img
                                height="150px"
                                width="150px"
                                src={croppedProfileImage}
                                // style={{
                                //   minHeight: "150px",
                                //   maxHeight: "150px",
                                // }}
                                alt="cropped"
                                id="croppedProfileImage"
                                className="rounded"
                              />

                              <IconButton
                                sx={{
                                  ...closeDeleteIconStyle,
                                  color: "#FC2125",
                                  fontSize: "1.4rem",
                                }}
                                onClick={() => {
                                  setCroppedProfileImage(null);
                                  setSelectedProfileFile(null);
                                  signUpFormRef?.current?.setFieldValue(
                                    "profile_img",
                                    ""
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </>
                        )}
                        {/* //  {isSubmit && showLogoError === 0 && ( */}
                        {touched?.profile_img &&
                          Boolean(errors?.profile_img) && (
                            <FormHelperText
                              sx={{
                                color: "#d32f2f !important",
                                m: "0 !important",
                                fontSize: "12px !important",
                              }}
                            >
                              Please add your profile picture
                            </FormHelperText>
                          )}
                        {update_agent_profile ? (
                          <>
                            {!selectedProfileFile && (
                              <Button
                                variant="outlined"
                                className="mt-2"
                                onClick={() => triggerFileUpload("profile")}
                              >
                                Edit <EditOutlinedIcon fontSize="small" />
                              </Button>
                            )}

                            {/* Dropzone mounted, just hidden if image exists */}
                            <div
                              ref={profileDropzoneRef}
                              style={{ display: "none" }}
                            >
                              <FileUpload
                                name="upload_doc"
                                accept={{
                                  "image/png": [".png", ".jpeg", ".jpg"],
                                }}
                                multiple={false}
                                onFileUpload={(file) =>
                                  onFileChange(file, "profile")
                                }
                                onFileDialogCancel={() => {
                                  setSelectedProfileFile(null);
                                }}
                                maxSize={10}
                              />
                            </div>
                          </>
                        ) : (
                          <p className="files_texts">
                            Click Here to Upload your Headshot that will appear
                            on all emails.
                          </p>
                        )}
                        <FormHelperText>
                          {touched.upload_doc && errors.upload_doc && (
                            <Typography
                              className="text-danger"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {errors.upload_doc}
                            </Typography>
                          )}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12} md={12} paddingTop={"10px"}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Logo
                        </InputLabel>
                        {/* When cropper will crop the image then we will have data in the croppedImage state so code will transfer to else condition */}
                        {!croppedImage ? (
                          selectedFile ? (
                            <>
                              {/* When select the image then this cropper will be displayed */}
                              <Cropper
                                ref={cropperLogoRef}
                                style={{ height: "150px", width: "150px" }}
                                zoomTo={0}
                                initialAspectRatio={1}
                                src={selectedFile}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false}
                                guides={false}
                              />
                              <Box
                                className="d-flex justify-content-start"
                                mt={2}
                              >
                                <Button
                                  onClick={() => getCropData("logo")}
                                  variant="contained"
                                  className="success-btn"
                                  style={{ padding: "6px 16px" }}
                                  // color="primary"
                                >
                                  {logoLoader ? (
                                    <>
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                      &nbsp;&nbsp;Processing..
                                    </>
                                  ) : (
                                    "Crop Photo"
                                  )}
                                </Button>
                              </Box>
                            </>
                          ) : (
                            <>
                              {/* Initially there is neither cropped image nor selected image so upload image field will show */}
                              <span className="servicer-signup-form">
                                <FileUpload
                                  name="logo"
                                  accept={{
                                    "image/png": [".png", ".jpeg", ".jpg"],
                                  }}
                                  multiple={false}
                                  onFileUpload={async (file) => {
                                    onFileChange(file);
                                  }}
                                  maxSize={10}
                                  dragDropText={`Drag and drop Company Logo or `}
                                  greenText="Upload a Company Logo"
                                  subText="Only .png, .jpeg, .jpg files are allowed"
                                  customFileUploadInnerSection={
                                    customFileUploadInnerSection
                                  }
                                />
                              </span>
                            </>
                          )
                        ) : (
                          <>
                            {/* After successfully cropping it will display the cropped image and delete icon for clearing the image */}
                            <Box
                              className="position-relative p-4 rounded d-flex justify-content-center align-items-center"
                              sx={{
                                backgroundColor: "#F6F9FC",
                                border: croppedImage ? "1px solid #E2E8EB" : "",
                                width: "150px",
                                height: "150px",
                              }}
                            >
                              <img
                                height="150px"
                                width="150px"
                                src={croppedImage}
                                // style={{
                                //   minHeight: "150px",
                                //   maxHeight: "150px",
                                // }}
                                alt="cropped"
                                id="croppedImage"
                                className="rounded"
                              />

                              <IconButton
                                sx={{
                                  ...closeDeleteIconStyle,
                                  color: "#FC2125",
                                  fontSize: "1.4rem",
                                }}
                                onClick={() => {
                                  setCroppedImage(null);
                                  setSelectedFile(null);
                                  signUpFormRef?.current?.setFieldValue(
                                    "logo",
                                    ""
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </>
                        )}
                        {/* //  {isSubmit && showLogoError === 0 && ( */}
                        {touched?.logo && Boolean(errors?.logo) && (
                          <FormHelperText
                            sx={{
                              color: "#d32f2f !important",
                              m: "0 !important",
                              fontSize: "12px !important",
                            }}
                          >
                            Please add your Company Logo
                          </FormHelperText>
                        )}
                        {update_agent_profile ? (
                          <>
                            {!selectedFile && (
                              <Button
                                variant="outlined"
                                className="mt-2"
                                onClick={() => triggerFileUpload("logo")}
                              >
                                Edit <EditOutlinedIcon fontSize="small" />
                              </Button>
                            )}
                            {/* Dropzone mounted, just hidden if image exists */}
                            <div
                              ref={logoDropzoneRef}
                              style={{ display: "none" }}
                            >
                              <FileUpload
                                name="upload_doc"
                                accept={{
                                  "image/png": [".png", ".jpeg", ".jpg"],
                                }}
                                multiple={false}
                                onFileUpload={(file) =>
                                  onFileChange(file, "logo")
                                }
                                onFileDialogCancel={() => {
                                  setSelectedFile(null);
                                }}
                                maxSize={10}
                              />
                            </div>
                          </>
                        ) : (
                          <p className="files_texts">
                            Click Here to Upload your Company Logo that will
                            appear on all emails.
                          </p>
                        )}
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={0.5}></Grid> */}
                    <Grid item xs={12} md={9}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6} px={1} pb={1}>
                          <InputLabel shrink htmlFor="bootstrap-input">
                            First name
                          </InputLabel>
                          <CustomTextField
                            name="first_name"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} px={1} pb={1}>
                          <InputLabel shrink htmlFor="bootstrap-input">
                            Last name
                          </InputLabel>
                          <CustomTextField
                            name="last_name"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                          />
                        </Grid>
                        {base_route === "title" && (
                          <>
                            <Grid item xs={12} md={6} px={1} pb={1}>
                              <InputLabel shrink htmlFor="bootstrap-input">
                                Brokerage name (will appear on all emails)
                              </InputLabel>
                              <CustomTextField
                                name="broker_name"
                                validation={{
                                  values,
                                  setFieldValue,
                                  touched,
                                  errors,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} px={1} pb={1} />
                          </>
                        )}

                        <Grid item xs={12} md={6} px={1} pb={1}>
                          <InputLabel shrink htmlFor="bootstrap-input">
                            Mobile number
                          </InputLabel>
                          <CustomPhoneNumberInput
                            name="phone"
                            placeholder={null}
                            values={values}
                            disabled={update_agent_profile}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue("phone", event);
                                if (!event) {
                                  setFieldValue("phone", "");
                                }
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} px={1} pb={1}>
                          <InputLabel shrink htmlFor="bootstrap-input">
                            Email address
                          </InputLabel>
                          <CustomTextField
                            name="email"
                            disabled={update_agent_profile}
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} px={1} pb={1}>
                          <InputLabel shrink htmlFor="bootstrap-input">
                            License number
                          </InputLabel>
                          <CustomTextField
                            name="license_number"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} px={1} pb={1}>
                          <InputLabel shrink htmlFor="bootstrap-input">
                            Your website
                          </InputLabel>
                          <CustomTextField
                            name="website"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                          />
                        </Grid>
                        {!update_agent_profile && (
                          <Grid item xs={12} md={6} px={1} pb={1}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                              Create a password
                            </InputLabel>
                            <OutlinedInput
                              className="textfield"
                              type={showConfirmPassword ? "text" : "password"}
                              name="password"
                              sx={{ backgroundColor: "#f6f9fc" }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle confirm_password visibility"
                                    onClick={() =>
                                      handleClickShowPassword(
                                        setShowConfirmPassword
                                      )
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              value={values?.password}
                              fullWidth
                              onChange={(event) => {
                                setFieldValue(
                                  "password",
                                  event.target.value?.trim()
                                );
                                // setPasswordcopy(event.target.value.trim());
                              }}
                              helperText={touched.password && errors.password}
                              error={
                                touched.password && Boolean(errors.password)
                              }
                            />
                            {touched.password && Boolean(errors.password) && (
                              <FormHelperText
                                sx={{
                                  color: "rgb(211, 47, 47) !important",
                                  fontSize: "12px !important",
                                  m: "0 !important",
                                  maxWidth: "100% !important",
                                }}
                              >
                                {touched.password && errors.password}
                              </FormHelperText>
                            )}
                          </Grid>
                        )}
                        <Grid item xs={12} md={6} px={1} pb={1}>
                          <InputLabel
                            shrink
                            style={{ color: "#00000075" }}
                            label="Office"
                          >
                            Which office do you work at
                          </InputLabel>
                          <FormControl
                            fullWidth
                            className="sign-textField text-start"
                            error={
                              touched.office_id && Boolean(errors.office_id)
                            }
                          >
                            <Select
                              labelId="demo-simple-select-placeholder"
                              id="demo-simple-select"
                              value={values?.office_id}
                              label="Choose your Title/Escrow Office"
                              name="office_id"
                              variant="outlined"
                              onChange={(event) => {
                                setFieldValue("office_id", event.target.value);
                              }}
                              MenuProps={MenuProps}
                              displayEmpty
                            >
                              {officeList?.map((offices, i) => (
                                <MenuItem value={offices?.id} key={i}>
                                  {offices?.office_name}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.office_id && Boolean(errors.office_id) && (
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                {touched.office_id && errors.office_id}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        {base_route === "title" && (
                          <Grid item xs={12} md={6} px={1} pb={1}>
                            <InputLabel
                              shrink
                              style={{ color: "#00000075" }}
                              label="Office"
                            >
                              Choose your{" "}
                              {base_route === "broker"
                                ? "Manager"
                                : "Representative"}
                            </InputLabel>
                            <FormControl
                              fullWidth
                              className="sign-textField text-start"
                              error={
                                touched.representative &&
                                Boolean(errors.representative)
                              }
                            >
                              <Select
                                labelId="demo-simple-select-placeholder"
                                id="demo-simple-select"
                                value={values?.representative}
                                label="Choose your Representative"
                                name="representative"
                                variant="outlined"
                                className="text-start"
                                onChange={(event) => {
                                  console.log("Select-->", event.target.value);
                                  setFieldValue(
                                    "representative",
                                    event.target.value
                                  );
                                }}
                                MenuProps={MenuProps}
                              >
                                {base_route === "broker" ? (
                                  <MenuItem value={"manager"} key={"1"}>
                                    Manager
                                  </MenuItem>
                                ) : (
                                  representativesList?.map((res, i) => (
                                    <MenuItem value={res?.id} key={i}>
                                      {res?.name}
                                    </MenuItem>
                                  ))
                                )}
                              </Select>
                              {touched.representative &&
                                Boolean(errors.representative) && (
                                  <FormHelperText sx={{ color: "#d32f2f" }}>
                                    {touched.representative &&
                                      errors.representative}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                        )}

                        {update_agent_profile && (
                          <>
                            <Grid item xs={12} md={6} px={1} pb={1}>
                              <InputLabel shrink htmlFor="bootstrap-input">
                                Enter old password
                              </InputLabel>
                              <OutlinedInput
                                className="textfield"
                                type={showPassword ? "text" : "password"}
                                name="old_password"
                                sx={{ backgroundColor: "#f6f9fc" }}
                                inputProps={{
                                  autoComplete: "new-confirm_password",
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle confirm_password visibility"
                                      onClick={() =>
                                        handleClickShowPassword(setShowPassword)
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                value={values?.old_password}
                                fullWidth
                                onChange={(event) => {
                                  setFieldValue(
                                    "old_password",
                                    event.target.value?.trim()
                                  );
                                  // setPasswordcopy(event.target.value.trim());
                                }}
                                helperText={
                                  touched.old_password && errors.old_password
                                }
                                error={
                                  touched.old_password &&
                                  Boolean(errors.old_password)
                                }
                              />
                              {touched.old_password &&
                                Boolean(errors.old_password) && (
                                  <FormHelperText
                                    sx={{
                                      color: "rgb(211, 47, 47) !important",
                                      fontSize: "12px !important",
                                      m: "0 !important",
                                      maxWidth: "100% !important",
                                    }}
                                  >
                                    {touched.old_password &&
                                      errors.old_password}
                                  </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} px={1} pb={1}>
                              <InputLabel shrink htmlFor="bootstrap-input">
                                Create a new password
                              </InputLabel>
                              <OutlinedInput
                                className="textfield"
                                type={showConfirmPassword ? "text" : "password"}
                                name="new_password"
                                sx={{ backgroundColor: "#f6f9fc" }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle confirm_password visibility"
                                      onClick={() =>
                                        handleClickShowPassword(
                                          setShowConfirmPassword
                                        )
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                value={values?.new_password}
                                fullWidth
                                onChange={(event) => {
                                  setFieldValue(
                                    "new_password",
                                    event.target.value?.trim()
                                  );
                                  // setPasswordcopy(event.target.value.trim());
                                }}
                                helperText={
                                  touched.new_password && errors.new_password
                                }
                                error={
                                  touched.new_password &&
                                  Boolean(errors.new_password)
                                }
                              />
                              {touched.new_password &&
                                Boolean(errors.new_password) && (
                                  <FormHelperText
                                    sx={{
                                      color: "rgb(211, 47, 47) !important",
                                      fontSize: "12px !important",
                                      m: "0 !important",
                                      maxWidth: "100% !important",
                                    }}
                                  >
                                    {touched.new_password &&
                                      errors.new_password}
                                  </FormHelperText>
                                )}
                            </Grid>
                          </>
                        )}

                        {!update_agent_profile && (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className="d-flex justify-content-center my-2"
                          >
                            <FormControl className="textField captcha-field">
                              <ReCAPTCHA
                                sitekey={RECAPTCHA_SITE_KEY}
                                onChange={handleCaptchaChange}
                                className="g-recaptcha"
                                ref={captchaRef}
                              />
                            </FormControl>
                          </Grid>
                        )}

                        {isSubmitting &&
                          !recaptchaToken &&
                          !update_agent_profile && (
                            <p className="text-danger text-center w-100 mb-3">
                              {isSubmitting && !recaptchaToken
                                ? "Please verify reCAPTCHA"
                                : null}
                            </p>
                          )}
                        {((getObjLength(agentCreateAccDetails) &&
                          !agentCreateAccDetails?.success) ||
                          (getObjLength(agentProfileListData) &&
                            !agentProfileListData?.success) ||
                          (getObjLength(updateAgentProfileData) &&
                            !updateAgentProfileData?.success)) && (
                          <p className="fw-500 terms_error w-100 text-danger text-center">
                            {update_agent_profile
                              ? updateAgentProfileData?.data?.message
                              : agentCreateAccDetails?.data?.message ||
                                agentProfileListData?.data?.message}
                          </p>
                        )}
                        {!update_agent_profile && (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            px={1}
                            className="d-flex justify-content-between align-items-center mt-3"
                          >
                            <Stack
                              direction={"row"}
                              spacing={1}
                              justifyContent={"flex-start"}
                              alignItems={"start"}
                              color={"black"}
                            >
                              <span className="py-1">
                                <CustomizedCheckbox
                                  onChecked={onCheckedAcknowledge}
                                />
                              </span>
                              <span className="pt-2 text-start terms_texts">
                                I hereby agree with the{" "}
                                <a
                                  target="_blank"
                                  href={AGENTS_TERMS_LINK}
                                  rel="noreferrer"
                                >
                                  <u>Terms and Conditions</u>
                                </a>
                              </span>
                            </Stack>

                            <Button
                              type="submit"
                              className="next_button"
                              variant="contained"
                            >
                              Create Account
                            </Button>
                          </Grid>
                        )}
                        {isSubmitting && !update_agent_profile
                          ? !checked && (
                              <span className="fw-400 terms_error text-danger">
                                You must agree to the terms and conditions to
                                proceed.
                              </span>
                            )
                          : null}
                      </Grid>
                    </Grid>
                    {update_agent_profile && (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        px={1}
                        className="d-flex justify-content-between align-items-center mt-3"
                      >
                        <Button
                          onClick={closeAgentProfileModal}
                          className="popup_Btn other_popup_btn"
                          variant="contained"
                        >
                          Discard
                        </Button>

                        <Button
                          type="submit"
                          className="popup_Btn success_popup_btn"
                          variant="contained"
                        >
                          Save Changes
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
      </Box>
      <Loader open={agentProfileLoader} />
    </Box>
  );
};

export default AgentsSignupForm;
