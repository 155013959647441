import { put, call, takeLatest } from "redux-saga/effects";
import { postAPIPayload } from "../../../apis/postApi";
import { getAPIPayload } from "../../../apis/getApi";
import { brokerAgentsAction } from "../../slices/brokers";

export function* fetchBrokerAgentsDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(brokerAgentsAction.fetchAgentCreateAccData(response));
}

export function* fetchBrokerDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(brokerAgentsAction.fetchBrokerData(response));
}

export function* fetchLoggedInAgentDetailsSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(brokerAgentsAction.fetchLoggedInAgentData(response));
}

export function* fetchAgentProfileListDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(brokerAgentsAction.fetchAgentProfileListData(response));
}

export function* fetchAgentPropertyListDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(brokerAgentsAction.fetchAgentPropertyListData(response));
}

export function* fetchSaveFreeMonitortingDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(brokerAgentsAction.fetchSaveFreeMonitortingData(response));
}

export function* fetchStartMarketringMailDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(brokerAgentsAction.fetchStartMarketringMailData(response));
}

export function* fetchAddNewAgentPropertyDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(brokerAgentsAction.fetchAddNewAgentPropertyData(response));
}

export function* fetchAgentProfileDataSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(brokerAgentsAction.fetchAgentProfileData(response));
}

export function* fetchUpdateAgentProfileDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(brokerAgentsAction.fetchUpdateAgentProfileData(response));
}

export function* fetchAgentPaymentDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(brokerAgentsAction.fetchAgentPaymentData(response));
}

export function* watchbrokerAgentsSagaAsync() {
  yield takeLatest(
    brokerAgentsAction.createAgentAccount,
    fetchBrokerAgentsDataSaga
  );
  yield takeLatest(brokerAgentsAction.getBrokerDetails, fetchBrokerDataSaga);
  yield takeLatest(
    brokerAgentsAction.getLoginAgentData,
    fetchLoggedInAgentDetailsSaga
  );
  yield takeLatest(
    brokerAgentsAction.getAgentProfileListData,
    fetchAgentProfileListDataSaga
  );
  yield takeLatest(
    brokerAgentsAction.getAgentPropertyListData,
    fetchAgentPropertyListDataSaga
  );
  yield takeLatest(
    brokerAgentsAction.getSaveFreeMonitortingData,
    fetchSaveFreeMonitortingDataSaga
  );
  yield takeLatest(
    brokerAgentsAction.getStartMarketringMailData,
    fetchStartMarketringMailDataSaga
  );
  yield takeLatest(
    brokerAgentsAction.getAddNewAgentPropertyData,
    fetchAddNewAgentPropertyDataSaga
  );
  yield takeLatest(
    brokerAgentsAction.getAgentProfileData,
    fetchAgentProfileDataSaga
  );
  yield takeLatest(
    brokerAgentsAction.getUpdateAgentProfileData,
    fetchUpdateAgentProfileDataSaga
  );
  yield takeLatest(
    brokerAgentsAction.getAgentPaymentData,
    fetchAgentPaymentDataSaga
  );
}
