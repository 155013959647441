import React, { useState } from "react";

import { useSelector } from "react-redux";

import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import CreditCardForm from "./CreditCardForm";
import logo from "../../assets/logo/equity_logo.png";
import info_icon from "../../assets/icons/info_icon.png";
import award_star from "../../assets/icons/award_star.png";
import check_img from "../../assets/icons/inv_checkmark.png";
import auto_activity from "../../assets/home/auto_activity_zone.svg";
import GoPrimierFrame from "../../assets/investor/Go Primier Frame.png";

const GoPremierFlow = ({ closePaymentModal = () => {} }) => {
  const [goPaymentScreen, setGoPaymentScreen] = useState(false);
  const { brokerAgentLoader } = useSelector((state) => state?.brokerAgents);
  return (
    <div className="broker-create-account m-1 text-start">
      {!goPaymentScreen ? (
        <Grid container>
          <Grid xs={12} md={6} className="frame_class">
            <img src={GoPrimierFrame} alt="Go Primier Frame" />
          </Grid>
          <Grid xs={12} md={6}>
            <Box className="premier_content">
              <Typography className="premier_title">GO Premium</Typography>
              <Typography className="pb-3">
                Get Started with Premium today for just{" "}
                <span className="fw-600">$19.99/Year</span> unlimited
                properties.
              </Typography>
              <Typography className="premier_subtitle">
                Why GO Premium?
              </Typography>
              <div className="px-2">
                <Stack direction={"row"} className="mt-3">
                  <img src={check_img} className="check_img" alt="check" />
                  <Typography className="text-title">
                    Branded Monthly Monitoring Emails
                    <Typography className="text-description">
                      Every monthly and notification email features your
                      branding, including your contact information and headshot.
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction={"row"} className="mt-3">
                  <img src={check_img} className="check_img" alt="check" />
                  <Typography className="text-title">
                    Protection Emails
                    <Typography className="text-description">
                      If the homeowner enrolls in EquityProtect's Premium
                      Services and Protection, your branding will be prominently
                      displayed.
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction={"row"} className="mt-3">
                  <img src={check_img} className="check_img" alt="check" />
                  <Typography className="text-title">
                    Discounted Services
                    <Typography className="text-description">
                      The Homeowner will receive additional services at
                      discounted rates courtesy of you.
                    </Typography>
                  </Typography>
                </Stack>
              </div>
              <div className="agent_button">
                <Button
                  className="premier_btn"
                  onClick={() => setGoPaymentScreen(true)}
                >
                  Go Premium
                  <Avatar
                    src={award_star}
                    alt="Go Premium"
                    sx={{ width: 22.6, height: 22.6 }} // Adjust size as needed
                  />
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          <img src={logo} alt="logo" className="logo-custom-width ep_logo" />
          <Grid container className="pay_ep_section">
            <Grid xs={12} md={6} className="price_frame">
              <Box className="premier_content">
                <Typography className="pay_title">Pay EquityProtect</Typography>
                <Typography className="py-3 price_title">$19.99</Typography>
                <Typography className="pay_subtitle">
                  Then 19.99 per year
                </Typography>

                <div className="img_alignment">
                  <img
                    src={auto_activity}
                    className="auto_activity"
                    alt="check"
                  />
                </div>
                <div className="price_alignment">
                  <div className="px-2 mt-3">
                    <Stack
                      direction={"row"}
                      className="mt-3"
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography className="info-title">
                        EquityProtect Premium
                      </Typography>
                      <Typography className="info-description">
                        $19.99
                      </Typography>
                    </Stack>
                  </div>
                  <div className="divider_class">
                    <Divider />
                  </div>

                  <div className="px-2 mt-3">
                    <Stack
                      direction={"row"}
                      className="mt-3"
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography className="info-title">Subtotal</Typography>
                      <Typography className="info-description">
                        $19.99
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      className="mt-3"
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography className="info-title d-flex align-items-center">
                        VAT
                        {/* <img src={info_icon} alt="info" className="px-1" /> */}
                      </Typography>
                      <Typography className="info-description">
                        $3.99
                      </Typography>
                    </Stack>
                  </div>
                  <div className="divider_class">
                    <Divider />
                  </div>
                  <div className="px-2 mt-3">
                    <Stack
                      direction={"row"}
                      className="mt-3"
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography className="info-title ">
                        Total due today
                      </Typography>
                      <Typography className="info-description">
                        $23.98
                      </Typography>
                    </Stack>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid xs={12} md={6} className="payment_frame">
              <CreditCardForm closePaymentModal={closePaymentModal} />
            </Grid>
          </Grid>
        </>
      )}
      <Loader open={brokerAgentLoader} />
    </div>
  );
};

export default GoPremierFlow;
