import React, { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { Loader } from "../common/loader";
import { getObjLength } from "../helpers/utils";
import { MenuProps } from "../helpers/constants";
import VisaCard from "../../assets/dashboard/visa.svg";
import CustomTextField from "../common/CustomTextField";
import SecurePaymentPanel from "../common/SecurePaymentPanel";
import MasterCard from "../../assets/dashboard/mastercard.svg";
import DiscoverCard from "../../assets/dashboard/discover.svg";
import { brokerAgentsAction } from "../../redux/slices/brokers";
import AmericanCard from "../../assets/dashboard/american-express.svg";
import powered_by_stripe from "../../assets/icons/powered_by_stripe.png";
import CustomizedCheckbox from "../common/customDesignedComponents/customCheckbox";

const goPrimumValidationSchema = Yup.object().shape({
  name: Yup.string("Please enter your Cardholder name.")
    .trim("The Cardholder name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Cardholder name is required"),
  // number: Yup.string("Please enter your Card number.")
  //   .trim("The Card number cannot include leading and trailing spaces")
  //   .required("Card number is required"),
  // expiry: Yup.string("Please enter your Card expiry date.").required(
  //   "Card expiry date is required"
  // ),
  // cvc: Yup.string("Please enter your Card CVC.").required(
  //   "Card CVC is required"
  // ),
  email: Yup.string("Please enter your Email Id.")
    .email("Invalid email format")
    .max(255)
    .required("Email id is required"),
  zip: Yup.string("Please enter your Zip code.")
    .required("Zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
});

// Card brand logos
const CARD_BRANDS = {
  visa: VisaCard,
  mastercard: MasterCard,
  amex: AmericanCard,
  discover: DiscoverCard,
  // unknown: "unknown",
};

const ALL_CARDS = Object.values(CARD_BRANDS);

const CreditCardForm = ({ closePaymentModal = () => {} }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [cardBrand, setCardBrand] = useState(null);
  const [checked, setChecked] = useState(false);
  const [ccTokenLoader, setCCTokenLoader] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [cardComplete, setCardComplete] = useState({
    number: true,
    expiry: true,
    cvc: true,
  });
  const [stripeErr, setStripeErr] = useState("");
  const errorMsg = useRef(null);

  // Function to handle scrolling to the form
  const scrollToForm = () => {
    errorMsg.current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      window.scrollBy(0, -300);
    }, 600);
  };

  const { agentProfileData, paymentLoader, agentPaymentData } = useSelector(
    (state) => state?.brokerAgents
  );

  const handleCardChange = (event, setFieldValue, type) => {
    if (type === "number") {
      setCardBrand(event.brand || null);
      setCardComplete((prev) => ({ ...prev, number: event.complete }));
      setFieldValue(type, event.complete ? event.complete : "");
    }
    if (type === "expiry") {
      setCardComplete((prev) => ({ ...prev, expiry: event.complete }));
      setFieldValue(type, event.complete ? event.complete : "");
    }
    if (type === "cvc") {
      setCardComplete((prev) => ({ ...prev, cvc: event.complete }));
      setFieldValue(type, event.complete ? event.complete : "");
    }
  };

  const handleSubmit = async (values) => {
    // event.preventDefault();

    if (!values.referral_code) {
      delete values.referral_code;
    }
    if (!values.promo_code) {
      delete values.promo_code;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    if (checked) {
      setCCTokenLoader(true);
    }
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);

    console.error(result?.error?.message);
    if (result?.error) {
      setStripeErr(result.error.message);
      setCCTokenLoader(false);
    } else {
      setStripeErr("");
      let data = { ...values };
      data.token = result.token.id;
      data.agent_id = agentProfileData?.data?.user_id;

      if (checked) {
        setCCTokenLoader(false);
        //API call
        dispatch(
          brokerAgentsAction.getAgentPaymentData({
            url: `agent/payment`,
            data,
            brokertoken: true,
          })
        );
      }
    }
  };

  const onCheckedAcknowledge = (e) => {
    setChecked(e.target.checked);
  };

  const onSuccessFun = () => {
    closePaymentModal();
    console.log("success on 3d payment");
  };

  const onFailedFun = () => {
    //    setOpen(false);
    console.log("failed on 3d payment");
  };

  useEffect(() => {
    if (getObjLength(agentPaymentData) && !agentPaymentData?.success) {
      scrollToForm();
    }
  }, [agentPaymentData]);

  return (
    <div className="payment_form">
      <SecurePaymentPanel
        displayLabel={false}
        cardData={null}
        paymentData={agentPaymentData}
        onSuccessFun={onSuccessFun}
        onFailedFun={onFailedFun}
        cardList={false}
        agent_id={agentProfileData?.data?.user_id}
        //  successMsg={successMsg()}
        successClose={false}
      />{" "}
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={{
          name: "",
          email: "",
          // number: "",
          // expiry: "",
          // cvc: "",
          country: "us",
          zip: "",
        }}
        validationSchema={goPrimumValidationSchema}
        onSubmit={(values) => {
          setIsFormSubmit(true);
          console.log(values);
          if (checked) {
            handleSubmit(values);
          }
        }}
      >
        {({
          values,
          setFieldValue,
          touched,
          errors,
          setTouched,
          isSubmitting,
        }) => {
          return (
            <>
              <Form>
                <Grid container spacing={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} px={1} pb={1}>
                      <InputLabel shrink htmlFor="bootstrap-input">
                        Email
                      </InputLabel>
                      <CustomTextField
                        classes={!errors?.email && "field_border"}
                        name="email"
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                      />
                    </Grid>
                    {/* Card Information Section */}
                    <Grid item xs={12} md={12} px={1}>
                      <InputLabel shrink className="mt-1 pb-1">
                        Card information
                      </InputLabel>

                      {/* Card Number + Logo */}
                      <Box
                        className="card_elements"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          <CardNumberElement
                            options={{
                              style: {
                                base: { fontSize: "16px", color: "#32325d" },
                              },
                            }}
                            onChange={(e) =>
                              handleCardChange(e, setFieldValue, "number")
                            }
                            onBlur={(e) => console.log("blurr", e)}
                          />
                        </Box>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          {cardBrand && CARD_BRANDS[cardBrand] ? (
                            cardBrand === "unknown" ? null : (
                              <img
                                src={CARD_BRANDS[cardBrand]}
                                alt={cardBrand}
                                style={{
                                  //  position: "absolute",
                                  //right: 61,
                                  //top: "26%",
                                  //  transform: "translateY(-50%)",
                                  paddingRight: "20px",
                                  height: 20,
                                }}
                              />
                            )
                          ) : (
                            ALL_CARDS.map((img, index) => (
                              <img
                                key={index}
                                src={img}
                                alt={img}
                                style={{ height: 11, width: 25 }}
                              />
                            ))
                          )}
                        </Box>
                      </Box>

                      {/* Expiry & CVC */}
                      <Grid container className="card_exp_cvc">
                        <Grid item xs={6}>
                          <Box className="exp_input">
                            <CardExpiryElement
                              options={{
                                style: {
                                  base: { fontSize: "16px", color: "#32325d" },
                                },
                              }}
                              onChange={(e) =>
                                handleCardChange(e, setFieldValue, "expiry")
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="cvc_input">
                            <CardCvcElement
                              options={{
                                style: {
                                  base: { fontSize: "16px", color: "#32325d" },
                                },
                              }}
                              onChange={(e) =>
                                handleCardChange(e, setFieldValue, "cvc")
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      {/* {isFormSubmit &&
                        (!cardComplete.number ||
                          !cardComplete.expiry ||
                          !cardComplete.cvc) && (
                          <p className="text-danger">
                            Please fill in all card fields correctly.
                          </p>
                        )} */}
                      {stripeErr && (
                        <p className="stripeValidation text-danger m-0 p-2">
                          {stripeErr}
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12} md={12} px={1} pb={1}>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        className="mt-2 pb-1"
                      >
                        Cardholder name
                      </InputLabel>
                      <CustomTextField
                        name="name"
                        placeholder="Full name on card"
                        classes={!errors?.name && "field_border"}
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} px={1} pb={1}>
                      <InputLabel shrink htmlFor="bootstrap-input">
                        Country or region
                      </InputLabel>
                      <FormControl
                        fullWidth
                        className="sign-textField text-start"
                        error={touched.country && Boolean(errors.country)}
                        placeholder="Full name on card"
                      >
                        <Select
                          labelId="demo-simple-select-placeholder"
                          id="demo-simple-select"
                          value={values?.country}
                          label="Country or region"
                          name="country"
                          placeholder="Country or region"
                          variant="outlined"
                          onChange={(event) => {
                            setFieldValue("country", event.target.value);
                          }}
                          MenuProps={MenuProps}
                          style={{ zIndex: 1 }}
                        >
                          <MenuItem value={"us"}>United States</MenuItem>

                          {/* {officeList?.map((offices, i) => (
                                    <MenuItem value={offices?.id} key={i}>
                                      {offices?.office_name}
                                    </MenuItem>
                                  ))} */}
                        </Select>
                        {touched.country && Boolean(errors.country) && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {touched.country && errors.country}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <CustomTextField
                        name="zip"
                        placeholder="ZIP"
                        classes={!errors?.zip && "field_border"}
                        validation={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} px={1} pb={1}></Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      px={1}
                      className="terms_section d-flex justify-content-between align-items-center mt-2"
                    >
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"start"}
                        color={"black"}
                      >
                        <div className="py-1">
                          <CustomizedCheckbox
                            onChecked={onCheckedAcknowledge}
                          />
                        </div>
                        <div>
                          <p className="pt-2 text-start terms_title">
                            Securely save my information for 1-click checkout
                          </p>
                          <p className="terms_texts">
                            Pay faster on EquityProtect and everywhere Link is
                            accepted
                          </p>
                        </div>
                      </Stack>
                    </Grid>
                    {isSubmitting
                      ? !checked && (
                          <span className="fw-500 w-100 text-center text-danger ">
                            You must agree to the terms and conditions to
                            proceed.
                          </span>
                        )
                      : null}
                    {getObjLength(agentPaymentData) &&
                    !agentPaymentData?.success ? (
                      <span ref={errorMsg} className="payment_error ">
                        {agentPaymentData?.data?.message}
                      </span>
                    ) : null}
                    <Grid
                      item
                      xs={12}
                      md={12}
                      px={1}
                      className="d-flex justify-content-between align-items-center mt-3"
                    >
                      <Button
                        type="submit"
                        className="pay_button"
                        variant="contained"
                        onClick={() => setIsFormSubmit(true)}
                      >
                        Pay and subscribe
                      </Button>
                    </Grid>
                    <div className="text-center">
                      <p className="confirming_text">
                        By confirming your subscription, you allow Powdur to
                        charge you for future payments in accordance with their
                        terms. You can always cancel your subscription.
                      </p>
                      <img src={powered_by_stripe} alt="powered_by_stripe" />
                    </div>
                  </Grid>
                </Grid>
              </Form>
            </>
          );
        }}
      </Formik>
      <Loader
        open={ccTokenLoader}
        message={"Do not close or reload the page while making payment."}
      />
      <Loader
        open={paymentLoader}
        message={"Do not close or reload the page while making payment."}
      />
    </div>
  );
};

export default CreditCardForm;
