import CryptoJS from "crypto-js";
import ReactPixel from "react-facebook-pixel";
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";

import CustomLinkWrapper from "../common/CustomLinkWrapper";
import {
  iv,
  MONTHS,
  PROFESSIONAL_TYPE,
  ROLES,
  secretKey,
  SUBDOMAIN,
  userTimeZone,
} from "./constants";

const DEFAULT_URL = process.env.REACT_APP_DEFAULT_URL;
const SERVER = process.env.REACT_APP_SERVER;

// to check url is image type or not
export const isImage = (url) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

// check any object contains particular key or not
const checkIfKeyExist = (objectName, keyName) => {
  let keyExist = Object.keys(objectName).some((key) => key === keyName);
  return keyExist;
};

export const generateTitleChainList = (propertiesDetails) => {
  const titleChainList = [];

  if (checkIfKeyExist(propertiesDetails, "GeneralInfo")) {
    titleChainList.push({
      type: "Year Built",
      year: propertiesDetails?.GeneralInfo[0]?.["Sale Date"]?.split("/")[2],
      sale_price: propertiesDetails?.GeneralInfo[0]?.["Year Built"],
      doc_link: propertiesDetails?.GeneralInfo[0]?.Doc_link,
      // doc_link: "https://www.google.com",
      deed_type: propertiesDetails?.GeneralInfo[0]?.["Property Type"],
    });
  }
  if (checkIfKeyExist(propertiesDetails, "SalesHistory")) {
    titleChainList.push({
      type: "Sale Deed",
      year: propertiesDetails?.SalesHistory[0]?.["Recording Date"]?.split(
        "/"
      )[2],
      sale_price: propertiesDetails?.SalesHistory[0]?.["Sale Price"],
      doc_link: propertiesDetails?.SalesHistory[0]?.Doc_link,
      deed_type: propertiesDetails?.SalesHistory[0]?.["Deed Type"],
    });
  }
  if (checkIfKeyExist(propertiesDetails, "FinanceHistory")) {
    titleChainList.push({
      type: "Mortgage",
      year: propertiesDetails?.FinanceHistory[0]?.["Loan Date"].split("/")[2],
      loan_amount: propertiesDetails?.FinanceHistory[0]?.["Loan Amount"],
      doc_link: propertiesDetails?.FinanceHistory[0]?.["Doc_link"],
      loan_type: propertiesDetails?.FinanceHistory[0]?.["Loan Type"],
    });
  }
  if (checkIfKeyExist(propertiesDetails, "ForeclosureInfo")) {
    titleChainList.push({
      type: "Mortgage",
      year: propertiesDetails?.ForeclosureInfo[0]?.["Loan Date"].split("/")[2],
      loan_amount: propertiesDetails?.ForeclosureInfo[0]?.["Loan Amount"],
      doc_link: propertiesDetails?.ForeclosureInfo[0]?.["Doc_link"],
      loan_type: propertiesDetails?.ForeclosureInfo[0]?.["Loan Type"],
    });
  }
  return titleChainList;
};

// to split the name from the url after specific index
export const extractNameFromUrl = (url, seperator, targetIndex) => {
  return url.split(seperator)[targetIndex];
};

// check url is valid or not
export const urlPatternValidation = (URL) => {
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  return regex.test(URL);
};

// it will return formated amount if amount is too big like 1.2K, 15.5M
export const formatAmount = (number, decPlaces) => {
  decPlaces = Math.pow(10, decPlaces);

  var abbrev = ["k", "m", "b", "t"];

  for (var i = abbrev.length - 1; i >= 0; i--) {
    var size = Math.pow(10, (i + 1) * 3);

    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;

      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      number += abbrev[i];
      break;
    }
  }

  return number;
};

// generate coordinates for sale area map
export const generateSaleAreaMapCoordinates = (saleData = []) => {
  return saleData?.length > 0
    ? saleData?.map((data) => {
        return {
          address: `$ ${formatAmount(
            !isNaN(data?.sa_val_transfer) ? data?.sa_val_transfer : 0,
            2
          )}`,
          lat: parseFloat(data?.sa_y_coord),
          lng: parseFloat(data?.sa_x_coord),
        };
      })
    : [];
};

// function for converting amount to particular currency
export const currencyFormatter = (amount, value = "-") => {
  return amount
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount)
    : value;
};

// round of any digit to 2 points
export const roundOfNum = (number) => {
  return (
    Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100
  ).toFixed(2);
};

export const roundOfNumIntrFloat = (number) => {
  let value = number;
  if (Number.isInteger(value)) {
    value.toString(); // Return as is
  } else {
    value.toFixed(2); // Convert to fixed decimal with 2 digits after the decimal point
  }
  return value;
};

// calculate % of the area covered by particular part of chart
export const calcPercentageOfChart = (arr, eleValue) => {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += parseFloat(arr[i]);
  }
  return roundOfNum((parseFloat(eleValue) / sum) * 100);
};

// amorization and interest calculator
export const amortizeLoan = (
  amount = 0,
  month_interest = 0,
  down_percent = 0,
  already_paid = 0,
  term = 0
) => {
  let mortgage = amount - already_paid;
  let sale_price = parseFloat(mortgage);
  let annual_interest_percent = parseFloat(month_interest);
  let year_term = term;
  let this_year_interest_paid = 0;
  let this_year_principal_paid = 0;
  let monthly_payment = false;

  let month_term = year_term * 12;
  let down_payment = sale_price * (down_percent / 100);
  let annual_interest_rate = annual_interest_percent / 100;
  let monthly_interest_rate = annual_interest_rate / 12;
  let financing_price = sale_price - down_payment;
  let monthly_factor = get_interest_factor(year_term, monthly_interest_rate);
  monthly_payment = financing_price / monthly_factor;

  // Set some base variables
  let principal = financing_price;
  // let current_month = 0;
  let current_year = new Date().getFullYear();
  // This basically, re-figures out the monthly payment, again.
  let power = -month_term;
  let denom = Math.pow(1 + monthly_interest_rate, power);
  monthly_payment = principal * (monthly_interest_rate / (1 - denom));

  let loanData = [];
  let interest_paid, principal_paid, remaining_balance, EMIPerMonth;

  let totalInterestPaid = 0;
  let xAxis = [];
  let mobileXAxis = [];
  let chartInterest = [];
  let chartPrinciple = [];
  let chartRemaining = [];

  for (let year = current_year; year < current_year + term; year++) {
    let yearData = [];
    yearData[year] = [];
    for (let month = 0; month < 12; month++) {
      interest_paid = principal * monthly_interest_rate;
      principal_paid = monthly_payment - interest_paid;
      remaining_balance = principal - principal_paid;

      this_year_interest_paid = this_year_interest_paid + interest_paid;
      this_year_principal_paid = this_year_principal_paid + principal_paid;

      let total_spent_this_year =
        this_year_interest_paid + this_year_principal_paid;

      yearData[year].push({
        [month]: {
          interest: roundOfNum(interest_paid),
          principal: roundOfNum(principal_paid),
          remaining: roundOfNum(remaining_balance),
        },
        total: {
          interest: roundOfNum(this_year_interest_paid),
          principal: roundOfNum(this_year_principal_paid),
          sum: roundOfNum(total_spent_this_year),
        },
      });

      xAxis.push(getMonthYear(month, year));
      mobileXAxis.push(getMobileMonthYear(month, year));
      chartInterest.push(roundOfNum(interest_paid));
      chartPrinciple.push(roundOfNum(principal_paid));
      chartRemaining.push(roundOfNum(remaining_balance));

      totalInterestPaid += roundOfNum(interest_paid);

      EMIPerMonth = roundOfNum(total_spent_this_year);

      this_year_interest_paid = 0;
      this_year_principal_paid = 0;

      principal = remaining_balance;
    }
    loanData.push(yearData);
  }

  let estimatedPrice = parseFloat(mortgage) + parseFloat(totalInterestPaid);
  let estimatedProgress =
    (parseFloat(amount) /
      (parseFloat(amount) + parseFloat(totalInterestPaid))) *
    100;

  return {
    loanData,
    estimatedPrice: roundOfNum(estimatedPrice),
    EMI: roundOfNum(EMIPerMonth),
    estimatedProgress: roundOfNum(estimatedProgress),
    chartData: {
      xAxis: xAxis,
      mobileXAxis: mobileXAxis,
      chartInterest: chartInterest,
      chartPrinciple: chartPrinciple,
      chartRemaining: chartRemaining,
    },
  };
};

function get_interest_factor(year_term, monthly_interest_rate) {
  let base_rate;

  let factor = 0;
  base_rate = 1 + monthly_interest_rate;
  let denominator = base_rate;
  for (let i = 0; i < year_term * 12; i++) {
    factor += 1 / denominator;
    denominator *= base_rate;
  }
  return factor;
}

// get Month-YYYY
export const getMonthYear = (month, year) => {
  return `${MONTHS[month]} - ${year}`;
};

// get Mon-YYYY
export const getMobileMonthYear = (month, year) => {
  return `${MONTHS[month]?.slice(0, 3)} - ${String(year)?.slice(2, 4)}`;
};

// to create local preview url of uploaded file
export const createFileUrl = (file) => {
  return URL.createObjectURL(file);
};

// get date in MM-DD-YYYY format
export const getDateInMMDDYYYYFormat = (date, seperator = "-") => {
  // return `${
  //   date?.getMonth() + 1
  // }${seperator}${date?.getDate()}${seperator}${date?.getFullYear()}`;
  return [
    `0${date?.getMonth() + 1}`?.slice(-2),
    `0${date?.getDate()}`?.slice(-2),
    `${date?.getFullYear()}`,
  ]?.join(seperator);
};

// get date in YYYY-MM-DD format
export const getDateInYYYYMMDDFormat = (date, seperator = "-") => {
  return [
    `${date?.getFullYear()}`,
    `0${date?.getMonth() + 1}`?.slice(-2),
    `0${date?.getDate()}`?.slice(-2),
  ]?.join(seperator);
};

// dropdown option with first name and last name of professionals in editProperty -> bindProfessional
export const generateProfessionalDropdownOptions = (arr, type) => {
  return arr?.length > 0
    ? arr
        ?.filter((item) => item?.professional_type?.type === type)
        ?.map((filteredItem) => ({
          key: filteredItem?.id,
          label: `${filteredItem?.first_name} ${filteredItem?.last_name}`,
          value: filteredItem?.id,
          is_editable: filteredItem?.is_editable,
        }))
    : [];
};

export const getObjLength = (data) => {
  let dd = data !== undefined && data !== null ? data : {};
  return Object.keys(dd)?.length > 0 ? true : false;
};

// get the name of the document from url
export const getURLName = (url = "") => {
  const urlArr = url?.split("/");
  return urlArr?.[urlArr?.length - 1];
};

// get Label-value pair from any array of object
export const getLabelValueFromArray = (arr, labelKey, valueKey) => {
  return arr?.map((item) => ({
    label: item?.[labelKey],
    value: item?.[valueKey],
  }));
};

// to get individual value of streetnumber, address, city, state, zipcode
export const getAddressFromGoogleAutoComplete = (
  address,
  key = "street_number"
) => {
  return address
    ? address?.address_components?.find((index) => index?.types?.includes(key))
      ? address?.address_components?.find((index) =>
          index?.types?.includes(key)
        )?.long_name
      : ""
    : "";
};

// to get individual value of streetnumber, address, city, state, zipcode with short_names
export const getShortNameAddressFromGoogleAutoComplete = (
  address,
  key = "street_number"
) => {
  return address
    ? address?.address_components?.find((index) => index?.types?.includes(key))
      ? address?.address_components?.find((index) =>
          index?.types?.includes(key)
        )?.short_name
      : ""
    : "";
};

export const checkFiniteCoordinate = (coordinate) => {
  return !isNaN(coordinate?.lat) && !isNaN(coordinate?.lng);
};

// return url link with green color if url exist
export const getUrlLink = (title, link) => {
  return urlPatternValidation(link) ? (
    <CustomLinkWrapper classes="green-link" title={title} link={link} />
  ) : (
    "-"
  );
};
// return url link with green color and file icon if url exist
export const getUrlLinkandFileIcon = (title, link) => {
  return urlPatternValidation(link) ? (
    <CustomLinkWrapper
      classes="green-link"
      title={title}
      link={link}
      img={link !== "-" ? true : false}
    />
  ) : (
    "-"
  );
};

// Date fromay by full month name ,Date , Year
export const getDateSeperated = (fulldate) => {
  if (fulldate && fulldate === "-") {
    return null;
  } else {
    let dateString = fulldate.split("/");
    console.log("datestring", dateString);
    const date = new Date(
      dateString[2],
      parseInt(dateString[0]) - 1,
      dateString[1]
    ); // 2009-11-10
    const month = date.toLocaleString("default", { month: "long" });
    // return { month: month, date: dateString[1], year: dateString[2] };
    return month + " " + dateString[1] + " , " + dateString[2];
  }
};

export const redirectSubDomainToMainDomain = (path) => {
  return (window.location.href = DEFAULT_URL + path);
};

export const censorWord = function (str) {
  if (str.length > 4) {
    return str[0] + str[1] + "*".repeat(str.length - 4) + str.slice(-2);
  } else {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  }
};

export const censorEmail = function (email) {
  if (email) {
    var arr = email?.split("@");
    return censorWord(arr[0]) + "@" + arr[1];
  } else {
    return email;
  }
};

// return formated phone number
export const getFormatedPhoneNumber = (phone) => {
  return phone?.slice(0, 2) !== "+1"
    ? formatPhoneNumberIntl(phone)
    : formatPhoneNumber(phone);
};

export const blobToImage = (blob) => {
  return new Promise((resolve) => {
    // const url = URL.createObjectURL(blob);
    let img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(blob);
      resolve(img);
    };
    img.src = blob;
  });
};

export const numberToWords = (num) => {
  const numList = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
  ];
  return numList[num];
};

export const user_signup_details = () => {
  return JSON.parse(localStorage.getItem("userSignupDetails"));
};
// Fire React Pixel events
export const reactPixelEvent = (pageView, event) => {
  if (SERVER === "production") {
    console.log(pageView, "=called==event==>", event);
    const fireEvents = () => {
      ReactPixel.pageView();
      ReactPixel.track(pageView, { event_param: event });
      if (window && window.gtag) {
        window.gtag("event", pageView, event);
      }
    };
    return fireEvents();
  }
};

export const reactPixelEventOnlyForCustomer = (pageView, event) => {
  let userRole = decryptPayload(localStorage.getItem("roles"));
  console.log("Role==>", userRole);
  // if (SERVER === "production") {
  if (userRole?.includes(ROLES.customer)) {
    console.log(pageView, "=called==event==> users", event);
    const fireEvents = () => {
      ReactPixel.pageView();
      ReactPixel.track(pageView, { event_param: event });
      // window.gtag("event", pageView, event);
    };
    return fireEvents();
  }
};

export const reactPixelEventOnConversion = (pageView, event) => {
  let storedId = `${Date.now()}-${Math.floor(Math.random() * 1000000)}`; // Generate unique ID;

  if (SERVER === "production") {
    const img = document.createElement("img");
    img.src = `https://trkn.us/pixel/c?ppt=25200&g=sitewide&gid=63320&ord=[${storedId}]`;
    img.height = "0";
    img.width = "0";
    img.border = "0";
    img.style.display = "none";
    img.id = "trackingPixel"; // Assign an ID for reference

    // Append to the body
    return document.body.appendChild(img);
  }
};

// get professional type label
export const getProfessionalTypeLabel = (professionalType) => {
  return professionalType === "lender"
    ? PROFESSIONAL_TYPE?.lender
    : professionalType === "broker"
    ? PROFESSIONAL_TYPE?.broker
    : professionalType === "provider"
    ? PROFESSIONAL_TYPE?.provider
    : PROFESSIONAL_TYPE?.other;
};

// capitalize the first character of the word in string
export const capitalizeFirstCharInWord = (str = "") => {
  return str
    ?.split(" ")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ");
};

// generate BG-color and display name on the profile picture
const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};
export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};
// to get 1st, 2nd , 3rd, 4th type text from number
export const ordinalSuffixOf = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

export const navigateToDomain = (route = "") => {
  let host = window.location.host;
  console.log("host==>", host);
  let parts = host.split(".");
  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  if (parts.length > 1) {
    // Remove the subdomain from the parts list
    parts.splice(0, 1);
  }
  console.log(parts[parts.length - 1], "pats==>2", parts);

  if (SERVER === "production") {
    window.location.href = `https://servicer.equityprotect.com/${route}`;
  } else if (SERVER === "staging") {
    window.location.href = `https://servicer-staging.equityprotect.com/${route}`;
  } else {
    window.location.href = `http://servicer.localhost:3000/${route}`;
  }
};

export const navigateToCustomerDomain = (route = "") => {
  let host = window.location.host;
  console.log("host==>", host);
  let parts = host.split(".");
  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  if (parts.length > 1) {
    // Remove the subdomain from the parts list
    parts.splice(0, 1);
  }
  console.log(parts[parts.length - 1], "pats==>2", parts);

  if (SERVER === "production") {
    window.location.href = `https://equityprotect.com/${route}`;
  } else if (SERVER === "staging") {
    window.location.href = `https://staging.equityprotect.com/${route}`;
  } else {
    window.location.href = `http://localhost:3000/${route}`;
  }
};

// get month from month name and year
export const getMonthFromString = (mon, year) => {
  return new Date(Date.parse(mon + " 1, " + year)).getMonth() + 1;
};

// get local date and time for createdAt date
export const getLocalFormatedDate = (createdAtDate, time = true) => {
  const utcDate = new Date(createdAtDate);

  // const userTime = utcDate?.toLocaleString(undefined, {
  //   timeZone: userTimeZone,
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   second: "2-digit",
  //   hour12: true,
  // });

  // return userTime?.toUpperCase()?.split(",")?.join(" ") || "-";
  const localDate = new Date(
    utcDate.toLocaleString("en-US", { timeZone: userTimeZone })
  );

  const month = String(localDate.getMonth() + 1).padStart(2, "0");
  const day = String(localDate.getDate()).padStart(2, "0");
  const year = localDate.getFullYear();

  const hours = localDate.getHours();
  const minutes = String(localDate.getMinutes()).padStart(2, "0");
  const seconds = String(localDate.getSeconds()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  const formattedHours = String(hours % 12 || 12).padStart(2, "0");

  const formattedDate = `${month}/${day}/${year}`;
  const formattedTime = `${formattedHours}:${minutes}:${seconds} ${ampm}`;

  return `${formattedDate} ${time ? formattedTime : ""}`;
};

// get local date and time for ExpiredAt date
export const getLocalFormatedDateForExpireDate = (expiredAt, timeString) => {
  const [day, month, year] = expiredAt.split("/").map(Number);
  // Create a new Date object
  const inputDate = new Date(year, month - 1, day);

  // Split the string into an array of strings
  const timeArray = timeString.split(", ");

  // Convert each string to a number
  const [hour, minutes, seconds] = timeArray.map(Number);

  // Set the time to 23:59:59
  inputDate.setHours(hour, minutes, seconds, 999);

  // Helper function to pad single-digit numbers with a leading zero
  function padZero(number) {
    return number.toString().padStart(2, "0");
  }

  // Helper function to format the time as "HH:mm:ss A"
  function formatTime(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const period = hours < 12 ? "AM" : "PM";

    return `${padZero(hours % 12)}:${padZero(minutes)}:${padZero(
      seconds
    )} ${period}`;
  }
  // Format the date to "DD/MM/YYYY HH:mm:ss A"
  const formattedDateString = `${padZero(month)}/${padZero(
    day
  )}/${year} ${formatTime(inputDate)}`;

  return formattedDateString;
};

export const calulateDatefromMonthsLater = (date, months) => {
  const inputDate = new Date(date); // i.e., date = 2023-09-29T12:04:34.000000Z
  const monthsLater = new Date(inputDate);
  monthsLater.setMonth(inputDate.getMonth() + months);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = monthsLater.toLocaleDateString("en-US", options);

  return formattedDate; // Output date: "November 29, 2023"
};

export const calulateDatefromDaysLater = (date, days, type) => {
  const currentDate = new Date(date);
  const futureDate = new Date(
    currentDate.getTime() + days * 24 * 60 * 60 * 1000
  );
  let afterOneMM = 0;
  let afterOneYY = 0;
  if (parseInt(days) === 0) {
    if (type === "MM") afterOneMM = 1;
    if (type === "YY") afterOneYY = 1;
  }

  const day = futureDate.getDate();
  const month = MONTHS[futureDate.getMonth() + afterOneMM];
  const year = futureDate.getFullYear() + afterOneYY;

  return `${month} ${day}, ${year}`;
};

export const loadScriptByURL = (id, url, callback = () => {}) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    script.defer = true;
    script.onload = callback;
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

// get Prefix of the url based on role customer / professional
export const getPrefixUrl = (userProfileDetails = {}) => {
  if (
    userProfileDetails?.data?.user_type?.includes(ROLES?.professional) &&
    !userProfileDetails?.data?.role?.includes(ROLES?.customer)
  ) {
    return "professional";
  } else {
    return "customer";
  }
};

/* Storing user's device details in a variable*/
export const isMobileDeviceCheck = (isMobile) => {
  let details = navigator.userAgent;

  /* Creating a regular expression 
containing some mobile devices keywords 
to search it in details string*/
  let regexp = /android|iphone|kindle|ipad/i;

  /* Using test() method to search regexp in details
it returns boolean value*/
  let isMobileDevice = regexp.test(details);

  if (isMobileDevice) {
    console.log("You are using a Mobile Device");
    return true;
  } else {
    console.log("You are using Desktop");
    return false;
  }
};

// Encryption function
export function encryptPayload(payload) {
  if (payload && payload !== "undefined") {
    const value = Array.isArray(payload) ? payload?.[0] : payload;
    const encryptedPayload = CryptoJS.AES.encrypt(value, secretKey, { iv: iv });
    const ciphertext = CryptoJS.enc.Base64.stringify(
      encryptedPayload.ciphertext
    );

    // Generate HMAC
    const hmac = CryptoJS.HmacSHA256(ciphertext, secretKey);
    const hmacString = CryptoJS.enc.Base64.stringify(hmac);

    // Append HMAC to ciphertext
    const finalCiphertext = ciphertext + hmacString;
    return finalCiphertext;
  } else {
    return payload;
  }
}

// Decryption function
export function decryptPayload(encryptedPayload) {
  if (encryptedPayload && encryptedPayload !== "undefined") {
    const receivedCiphertext = encryptedPayload.substring(
      0,
      encryptedPayload.length - 44
    ); // Assuming the HMAC is 44 characters long

    const hmacReceived = encryptedPayload.substring(
      encryptedPayload.length - 44
    );
    const hmacExpected = CryptoJS.enc.Base64.stringify(
      CryptoJS.HmacSHA256(receivedCiphertext, secretKey)
    );

    if (hmacReceived !== hmacExpected) {
      // console.log("Invalid HMAC. Ciphertext may be tampered.");
      return null;
    } else {
      const decrypted = CryptoJS.AES.decrypt(receivedCiphertext, secretKey, {
        iv: iv,
      });
      const plaintext = decrypted.toString(CryptoJS.enc.Utf8);
      return plaintext;
    }
  } else {
    return encryptedPayload;
  }
}
// encrypt password
function xorEncrypt(data, key) {
  // Ensure key is a string

  if (typeof key !== "string") {
    throw new Error("Key must be a string");
  }

  return data
    .split("")
    .map((char, index) => {
      return String.fromCharCode(
        char.charCodeAt(0) ^ key.charCodeAt(index % key.length)
      );
    })
    .join("");
}

function base64Encode(data) {
  return btoa(data); // btoa() encodes a string to Base64
}

export function encryptData(data, key) {
  const xorEncrypted = xorEncrypt(data, key);
  return base64Encode(xorEncrypted);
}

export const daysPassedSinceISODate = (isoDate) => {
  // Parse the ISO date string to a Date object
  const startDate = new Date(isoDate);
  // console.log("sentDate", startDate);

  // Get the current date
  const currentDate = new Date();
  // console.log("currentDate", currentDate);

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - startDate;
  // console.log(
  //   "timeDifference",
  //   timeDifference,
  //   "dayPassed",
  //   timeDifference / (1000 * 60 * 60 * 24)
  // );

  // Convert milliseconds to days
  const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  // console.log("dayPassed", daysPassed);

  return daysPassed;
};

// get data from other array based on subcription id
export function getDatafromArray(value, data, fetchID) {
  if (Array.isArray(value)) {
    return value.map((id) => {
      const item = data.find((item) => item.subscription?.id === id);
      return item?.address;
    });
  } else {
    const item = data.find((item) => item.subscription?.id === value);
    return item?.address;
  }
}
// get data from other array based on subcription id
export function filteroutPlanData(selectedData) {
  let recording_fees = selectedData?.plans?.[0]?.additional_charges
    ?.recording_fees
    ? selectedData?.plans?.[0]?.additional_charges?.recording_fees
    : null;

  let monthly_plans = {
    name: "Monthly",
    is_active: selectedData?.plans?.find((plan, i) => plan?.name === "Monthly")
      ? true
      : false,
    amount: selectedData?.plans?.find((plan, i) => plan?.name === "Monthly")
      ? selectedData?.plans?.find(
          (plan, i) => plan?.name === "Monthly" && plan?.is_default
        )?.amount
      : null,
    setup_fees: selectedData?.plans?.find((plan, i) => plan?.name === "Monthly")
      ? selectedData?.plans?.find(
          (plan, i) => plan?.name === "Monthly" && plan?.is_default
        )?.additional_charges?.["setup fees"]
      : null,
    trial_period: selectedData?.plans?.find(
      (plan, i) => plan?.name === "Monthly"
    )
      ? selectedData?.plans?.find(
          (plan, i) => plan?.name === "Monthly" && plan?.is_default
        )?.trial_period
      : null,
    monthly_renewal_plans: selectedData?.plans?.find(
      (plan, i) => plan?.name === "Monthly"
    )
      ? selectedData?.plans?.find(
          (plan, i) => plan?.name === "Monthly" && !plan?.is_default
        )?.amount
      : null,
  };

  let yearly_plans = {
    name: "Yearly",
    is_active: selectedData?.plans?.find((plan, i) => plan?.name === "Yearly")
      ? true
      : false,
    amount: selectedData?.plans?.find((plan, i) => plan?.name === "Yearly")
      ? selectedData?.plans?.find(
          (plan, i) => plan?.name === "Yearly" && plan?.is_default
        )?.amount
      : null,
    trial_period: selectedData?.plans?.find(
      (plan, i) => plan?.name === "Yearly"
    )
      ? selectedData?.plans?.find(
          (plan, i) => plan?.name === "Yearly" && plan?.is_default
        )?.trial_period
      : null,
    yearly_renewal_plans: selectedData?.plans?.find(
      (plan, i) => plan?.name === "Yearly"
    )
      ? selectedData?.plans?.find(
          (plan, i) => plan?.name === "Yearly" && !plan?.is_default
        )?.amount
      : null,
  };

  let data = { recording_fees, monthly_plans, yearly_plans };
  return data;
}

// Set read more if string contains more than 80 letters
export const readMoreSentance = (str = "") => {
  return `${str.slice(0, 65)}${str.length > 65 ? `...` : ""}`;
};

//Clear localstorage values.
export const clearLocalStoarge = (term) => {
  let subUsersList = localStorage.getItem("subUsersList");
  let county_details = {};
  let is_scrp_hp = localStorage.getItem("scrp_hp");
  if (
    term === "check_county" &&
    is_scrp_hp &&
    !is_scrp_hp.includes("undefined")
  ) {
    county_details = {
      is_scrp_hp,
      countyCode: localStorage.getItem("county-code"),
    };
  }
  localStorage.clear();
  localStorage.setItem("preference", true);
  localStorage.setItem("subUsersList", subUsersList);
  // upadte couynty if it's already available
  if (getObjLength(county_details)) {
    localStorage.setItem("scrp_hp", county_details?.is_scrp_hp);
    localStorage.setItem("county-code", county_details?.countyCode);
  }
};

// Managed the sub user's login counts
export const subUsersLoginCount = (uID) => {
  let data =
    JSON.parse(decryptPayload(localStorage.getItem("subUsersList"))) || [];
  const userExistsIndex = data.findIndex((item) => item.user_id === uID);
  if (userExistsIndex !== -1) {
    // User exists, update count
    data[userExistsIndex].count += 1;
  } else {
    // User does not exist, add new user
    data.push({ user_id: uID, count: 0 });
  }
  localStorage.setItem("subUsersList", encryptPayload(JSON.stringify(data)));
};

// Managed the sub user's login counts
export const richSnippetImplementation = (uID) => {
  let scriptElement = null;
  if (process.env.REACT_APP_SERVER === "production") {
    scriptElement = document.createElement("script");
    scriptElement.type = "application/ld+json";
    scriptElement.text = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "WebSite",
      name: "Equity Protect",
      url: "https://www.equityprotect.com/",
      potentialAction: {
        "@type": "SearchAction",
        target:
          "https://www.equityprotect.com/usecases{search_term_string}{search_term_string}",
        "query-input": "required name=search_term_string",
      },
    });
  }

  // Return null if not in production
  return scriptElement;
};

// to set OTP foucs on the 1st input field
export const autoFocusOnField = () => {
  setTimeout(() => {
    const firstInput = document.querySelector(
      '[aria-label="Please enter verification code. Digit 1"]'
    );
    if (firstInput) {
      firstInput.focus();
    }
  }, 100);
};

// to set OTP foucs on the 1st input field
export const convertPriceStringToNumber = (priceString) => {
  // Remove commas from the string
  const stringWithoutCommas = priceString.replace(/,/g, "");
  // Parse the string into a float number
  const numberValue = parseFloat(stringWithoutCommas);
  // If the parsed number has decimal places, return it as is, otherwise, return the integer part
  return isNaN(numberValue)
    ? null
    : Number.isInteger(numberValue)
    ? parseInt(numberValue)
    : numberValue;
};

// fecth the custom domain name
export const customeDomainName = (url) => {
  console.log("pathname==>", url);
  const getURL = url?.split(".");
  let customeDomainName = null;
  if (getURL?.[0]?.includes(SUBDOMAIN?.equityprotect)) {
    customeDomainName = getURL?.[1];
  } else {
    customeDomainName = null;
  }

  return customeDomainName;
};

// calculate how many days behind a specific date
export const calculateDaysBehind = (inputDateString) => {
  const inputDate = new Date(inputDateString);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = currentDate - inputDate;

  // Convert milliseconds to days
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  return differenceInDays;
};

// sepearte data objects for property risk report payload
export const propertyRiskReportDataObj = (data) => {
  console.log("propertyRiskReportDataObj, data==>", data);
  let user_data = {
    first_name: data?.name?.split(" ")?.[0],
    last_name: data?.name?.split(" ")?.[1],
    email: data?.email,
    phone: data?.phone,
  };
  let param = {
    street_number: data?.street_number,
    site_route: data?.site_route,
    city: data?.city,
    state: data?.state,
    state_name: data?.state_name,
    zip: data?.zip,
    unit_no: data?.unit_no,
    county: data?.county,
  };
  let fixParams = {
    country: data?.country,
    latitude: data?.latitude,
    longitude: data?.longitude,
    address: data?.address_1 || data?.address,
  };
  let searched_Add = data?.searched_Add || data?.address;
  let property_search_data = { user_data, param, fixParams, searched_Add };
  return property_search_data;
};

// To count the risk level
export const riskLevelCount = (status) => {
  let count = 0;
  if (status === "Low") {
    count = 1;
  } else if (status === "Medium-Low") {
    count = 2;
  } else if (status === "Medium") {
    count = 3;
  } else if (status === "Medium-High") {
    count = 4;
  } else if (status === "High") {
    count = 5;
  }
  return count;
};

//Calling Andriod app methods on login/biometric/logout
export const windowsAndroidNativeMethods = (type, payload) => {
  console.log(type, "Android method called...", window?.Android);
  let androidMethod = null;
  console.log(
    window?.Android,
    "<== is android -- Android method type==>",
    type
  );
  if (window?.Android) {
    if (type === "login") {
      console.log("Android Login method called...");
      androidMethod = window?.Android?.login();
    } else if (type === "biometric") {
      console.log(
        "Android Biometric method called...",
        JSON.stringify(payload)
      );
      androidMethod = window?.Android?.biometric(JSON.stringify(payload));
    } else if (type === "logout") {
      console.log("Android Logout method called...");
      androidMethod = window?.Android?.logout();
    } else {
      androidMethod = null;
    }
  }
  return androidMethod;
};
